@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./toast-fix.css');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* light theme custom */
[data-theme='light'] {
  background: #eef1f7;
}

[data-theme='dark'] {
  background: #000000;
}

.table :where(:first-child) :where(:first-child) :where(th, td):last-child,
.table :where(:last-child) :where(:last-child) :where(th, td):first-child,
.table :where(:first-child) :where(:first-child) :where(th, td):first-child {
  border-radius: 0;
}
// [data-theme='light'] .table :where(thead, tfoot) :where(th, td) {
//   color: hsla(var(--bc) / var(--tw-text-opacity, 1));
//   font-weight: unset;
//   font-size: 1.05rem;
//   background-color: unset;
//   text-transform: unset;
// }

// [data-theme='light'] .table :where(tbody th, tbody td) {
//   --tw-bg-opacity: 1;
//   background-color: unset;
// }

// .table-dense table.table :where(thead, tfoot) tr :where(th, td) {
//   border-radius: unset;
//   border-right: 1px solid hsl(var(--bc) / 0.1);
//   background: hsl(var(--b1) / var(--tw-bg-opacity));
// }

.tab.tab-active {
  border-color: hsl(var(--p) / var(--tw-text-opacity));
}

/* --------------------- Override Original Color --------------------------  */
.tabs-boxed .tab-active {
  background-color: #f2f4f7;
  color: #333;
}
.tabs-boxed .tab-active:hover {
  color: #333;
}
/* --------------------- Override Original Color --------------------------  */

[data-theme='light'] .divider-x {
  border-bottom: 1px solid #e5e7eb;
}

[data-theme='light'] .divider-y {
  border-right: 1px solid #e5e7eb;
}

[data-theme='dark'] .divider-x {
  border-bottom: 1px solid #222;
}

[data-theme='dark'] .divider-y {
  border-right: 1px solid #222;
}

#root .react-datepicker-popper {
  z-index: 15;
}

.z-markdown img {
  margin: 0;
}

.published-button:hover {
  .when-hover {
    display: inline-block;
  }
  .when-not-hover {
    display: none;
  }
}

.published-button {
  .when-hover {
    display: none;
  }
  .when-not-hover {
    display: inline-block;
  }
}

.cm-editor {
  height: 100%;
}

.tooltip:before {
  z-index: 1;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.rounded-none .ant-input-group-addon {
  border-radius: 0;
}

.ant-table-footer,
.ant-table-cell,.ant-table-selection-column,
.ant-table-thead > tr > th {
  background-color: transparent !important;
}

.ant-btn-primary {
  background-color: #263dff;;
  border-color: #263dff;;
}

a:hover {
  color: #263dff !important;
}
a.get-started:hover {
  color: #fff !important;
}
.divider:before, .divider:after {
  height: 1px;
}
.ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-2q8sxy).ant-table-wrapper .ant-table-thead >tr>td {}

.ant-table-wrapper .ant-table-thead>tr>th {
  padding-bottom: 10px;
}

th.ant-table-cell.ant-table-column-has-sorters::before, .ant-table-wrapper .ant-table-thead>tr>th::before {
  display: none;
}

.ant-table .ant-table-column-title, th.ant-table-cell {
  font-size: 12px;
  font-weight: normal !important;
}

td.ant-table-cell {
  font-weight: 500;
}

.preview-table .ant-table-thead > tr {
  background: #f8f8f8;
}

.ant-table-wrapper .ant-table-thead>tr>th{
  color: #777A80;
}

.ant-table-cell.ant-table-selection-column {
  margin-right: -10px;
}

.project-table .ant-table-footer {
  padding: 0;
}

.v2 .btn-primary  {
  background-color: #263dff;
  border-color: #263dff;
}
.v2 

.v2 .btn-error {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
}
.v2 .text-primary {
  color: #263dff;
}

.use-form .ant-form-item .ant-form-item-label >label {
  font-size: 12px;
  color: #777A80;
}


.hid .ant-select-selection-item {
  display: none;
}
.hid .ant-select-single:not(.ant-select-customize-input) .ant-select-selector  {
  padding: 0 16px;
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #fff;
  height: 28px;
  border-left: 1px solid #e5e7eb;
  border-radius: 0;
}
.hid .ant-select .ant-select-arrow {
  color: #fff;
}

.ant-select-dropdown {
  min-width: 160px !important;
}


.xscroll {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

input.h30 {
  height: 30px;
}

.billing-modal .ant-modal-content {
  padding: 0;
  border-radius: 4px;
}

 .menu :where(li:not(.menu-title):not(:empty)) > :where(:not(ul).\!active), 
 .menu :where(li:not(.menu-title):not(:empty)) > :where(*:not(ul):active) {
  background-color: #dbe0fc !important;
}

.v2-dashboard {
  .btn-primary  {
    color: #fff;
  }
  .btn-group {
    .btn-primary {
      color:  #fff;
    }
  }
}

.v2 {
  .ant-tag {
    cursor: auto;
  }
  .gray-bg {
    background: #ccc;
    border: #bbb;
  }
}

.logout-dropdown.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 0;
}

.gql-editor-wrapper .cm-theme-light {
  height: 100% ;
}

.ant-menu-submenu-title:hover,
.ant-menu-submenu .ant-menu-inline{
  background-color: transparent !important;
}
.ant-menu-root {
  border-right: 0 none !important;
}
.ant-menu-light .ant-menu-item-selected {
  background-color: #dae0ff !important;
}
.ant-menu-light .ant-menu-submenu-title {
  white-space: nowrap !important;
}
.ant-menu-light .ant-menu-item:hover {
  color: #263dff !important;
}
.ant-menu-light .ant-menu-submenu-selected .ant-menu-submenu-title{
  color: rgba(0, 0, 0, .88);
}